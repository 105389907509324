<template>
  <div class="gestion-type-reglement">
    <div class="ligne-box-setting-header"></div>
    <div class="body-box-setting">
      <div class="table-rapport-style w-100-p" style="width: 100%">
        <b-table
          show-empty
          id="my-table"
          class="custom-table-style table-gestion-type-reglement-style"
          :items="TypeReglement"
          :fields="fields"
          bordered
          sticky-header
          hover
          head-variant="light"
          tbody-tr-class="ligneNormale"
          empty-text="Il n'y a aucun enregistrement à afficher"
        >
          <template v-slot:cell(name)="data" class="nomCategorieSize">
            {{ data.item.name }}
          </template>
          <template v-slot:cell(condition_name)="data" class="nomCategorieSize">
            {{ data.item.condition_name }}
          </template>
          <template v-slot:cell(Actions)="data" class="actionCategorieSize">
            <b-button
              v-if="checkPermission('GTRHTSTR')"
              class="button-danger-style m-2"
              size="sm"
              variant="danger"
              @click.prevent.stop="deleteCurrentType(data.item)"
            >
              <font-awesome-icon icon="trash" />
            </b-button>
            <b-button
              v-if="checkPermission('GTRHTMTR')"
              class="button-danger-style m-2"
              size="sm"
              variant="success"
              @click.prevent.stop="updateCurrentType(data.item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </b-button>
          </template>
        </b-table>
        <div class="footer-style mt-2">
          <b-pagination
            v-model="page"
            :total-rows="rowReglement"
            :per-page="perPage"
            aria-controls="my-table"
            pills
            align="center"
            size="sm"
            @change="pagination"
            class="pagination-style"
          ></b-pagination>

          <div class="per-page-element-style">
            <div class="box-label-champ">
              <div class="label-box-style">
                <span class="title-tabel">Eléments par page</span>
              </div>
            </div>
            <b-form-select
              v-model="perPage"
              :options="perPageList"
              @change="changePerPage"
              class="b-form-select-new-style bg-select"
            ></b-form-select>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      ref="deleteTypeReglement"
      id="deleteTypeReglement"
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal()"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Supprimer un type paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('deleteTypeReglement')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>

      <form @submit.prevent="handleDeleteType" class="form-modal-custom-style">
        <Card>
          <template v-slot:body>
            <div class="mt-4 ml-3">
              <p class="text-dark">
                Êtes-vous sur de vouloir supprimer ce type du paiement
                <strong>{{ typeToDelete.name }}</strong
                >?
              </p>
            </div>
            <div class="actionModel">
              <div class="messageError">
                <div v-if="error" class="error">
                  <ul v-if="Array.isArray(error)">
                    <li v-for="(e, index) in error" :key="'error' + index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ error }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style mt-1">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </template>
        </Card>
      </form>
    </b-modal>
    <b-modal
      id="updateTypeReglement"
      ref="updateTypeReglement"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <div class="hader mb-2">
        <div class="titleSetting">Modifier type paiement</div>
        <div
          class="iconClose"
          @click.prevent="hideModal('updateTypeReglement')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="handleupdateTypeReglement"
            class="form-modal-custom-style"
          >
            <b-form-group label="Nom" label-for="nom" class="input-modal-champ">
              <b-form-input
                autocomplete="off"
                v-model="typeReglementToUpdate.name"
              ></b-form-input>
            </b-form-group>
            <div class="messageError">
              <div v-if="errorNameReglement" class="error">
                <ul
                  v-if="Array.isArray(errorNameReglement)"
                  style="list-style-type: none"
                >
                  <li v-for="(e, index) in errorNameReglement" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ errorNameReglement }}</div>
              </div>
            </div>
            <div class="actionModel mt-3">
              <div class="messageError">
                <div v-if="errorReglement" class="error">
                  <ul
                    v-if="Array.isArray(errorReglement)"
                    style="list-style-type: none"
                  >
                    <li v-for="(e, index) in errorReglement" :key="index">
                      {{ e }}
                    </li>
                  </ul>
                  <div v-else>{{ errorReglement }}</div>
                </div>
              </div>
              <b-button type="submit" class="button-valide-style">
                <span>
                  Valider
                  <div v-if="loading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div>
                  </div>
                </span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Gestion-type-reglement',
  data() {
    return {
      type: null,
      error: null,
      loading: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 },
      ],
      fields: [
        {
          key: 'name',
          label: 'Type Paiement',
          thStyle: { width: '100%' },
        },
        { key: 'Actions', label: 'Actions' },
      ],
      typeToDelete: null,
      typeToUpdate: null,
    };
  },
  computed: {
    ...mapGetters([
      'errorNameReglement',
      'TypeReglement',
      'errorReglement',
      'checkPermission',
      'rowReglement',
    ]),
  },
  methods: {
    ...mapActions([
      'getAllTypesReglement',
      'deleteTypeReglement',
      'updateTypeReglement',
      'resetMutations',
    ]),
    async changePerPage() {
      this.page = 1;
      await this.getAllTypesReglement({
        per_page: this.perPage,
        page: this.page,
      });
    },
    async pagination(paginate) {
      this.page = paginate;
      await this.getAllTypesReglement({
        per_page: this.perPage,
        page: this.page,
      });
    },
    resetModal() {
      this.type = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.resetMutations();
    },
    deleteCurrentType(data) {
      this.typeToDelete = data;
      this.$refs['deleteTypeReglement'].show();
    },

    async handleDeleteType() {
      this.loading = true;
      const response = await this.deleteTypeReglement(this.typeToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteTypeReglement');
      } else {
        this.loading = false;
      }
    },
    updateCurrentType(data) {
      this.typeReglementToUpdate = { ...data };
      this.oldTypeReglement = data;
      this.$refs['updateTypeReglement'].show();
    },
    async handleupdateTypeReglement() {
      this.loading = true;
      const response = await this.updateTypeReglement({
        typeReglementToUpdate: this.typeReglementToUpdate,
        oldTypeReglement: this.oldTypeReglement,
      });
      if (response) {
        this.loading = false;
        this.hideModal('updateTypeReglement');
      } else {
        this.loading = false;
        this.error = this.errorReglement;
      }
    },
  },
  components: {
    Card: () => import('@/views/component/card'),
  },
  async mounted() {
    await this.getAllTypesReglement({
      per_page: this.perPage,
      page: this.page,
    });
  },
};
</script>

<style lang="scss" scoped>
.gestion-type-reglement {
  padding: 15px;
  padding-top: 5px;
  overflow: hidden;
  width: 100%;
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-type-reglement-style {
    max-height: calc(100vh - 310px) !important;
    height: calc(100vh - 310px) !important;
    margin-bottom: 0px;
  }
  .body-box-setting {
    height: calc(100vh - 240px) !important;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.widthTitle {
  width: 300px;
}
.loaderPosition {
  display: flex;
  flex-direction: row;
}
</style>
